import axios from "axios";
import config from "config";
import { authStorage } from "services/authStorage";
import { firebase } from "./firebase";
import NProgress from "nprogress";
import { reportError } from "@services/error-reporting";

const axiosInstance = axios.create({
  baseURL: "https://frupro-prod-dc2fe6k.nw.gateway.dev/api/",
});

let token = authStorage.token;

const startProgress = () => {
  if (typeof window !== "undefined") {
    NProgress.start();
  }
};

const stopPregress = () => {
  if (typeof window !== "undefined") {
    NProgress.done();
  }
};

axiosInstance.interceptors.request.use(
  async function (config) {
    if (firebase.auth().currentUser) {
      await firebase
        .auth()
        .currentUser.getIdToken()
        .then(function (idToken) {
          if (token !== idToken) authStorage.token = idToken;
          token = idToken;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    const _config = token
      ? {
          Authorization: token ? `Bearer ${token}` : "",
        }
      : {};
    startProgress();
    return {
      ...config,
      headers: {
        ..._config,
        ...config.headers,
      },
    };
  },
  function (error) {
    stopPregress();
    return Promise.reject(error);
  }
);
// if (process.browser) {
//   window.onerror = function (message, url, line, column, error) {
//     window.onerror = function (message, url, line, column, error) {
//       const errorData = { message, url, line, column, error };
//       reportError({ message: errorData.message, errorData });
//     };

//     window.addEventListener('unhandledrejection', (event) => {
//       const error = event.reason;
//       const errorData = { message: error.message, stack: error.stack };
//       reportError({ message: errorData.message, errorData });
//     });
//   }
// }

axiosInstance.interceptors.response.use(
  function (response) {
    stopPregress();
    return response;
  },
  function (error) {
    let pathname = window.location.pathname;

    const errorData = { message: error.message, stack: error.stack };
    reportError({
      errorMessage: error.message,
      page: pathname,
      errorData: errorData,
    }).then((resp) => {
      console.log(resp.data);
    });
    stopPregress();
    if (error.response) {
      if (
        error.response.status === 429 &&
        error.response.headers["retry-after"]
      ) {
        return Promise.reject({
          message: `Your account has been blocked for too many attempts. Please retry after ${error.response.headers["retry-after"]} second`,
        });
      }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
